/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////// LAB /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_lab';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    padding-top: 0;
}

/* ----------------------------------------------------------------------- LOGO --- */
#{$b} a.logo::after {
    color: white;
    @media (max-width: $breakpoint2) {
        content: 'Grace';
    }
}

/* --------------------------------------------------------------------- HEADER --- */
#{$b} header a.logo {
    color: white;
}
#{$b} header nav,
#{$b} header nav a.active {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header nav:hover {
    color: rgba(white, 0.5);
}
#{$b} header nav a:hover {
    color: white;
}
#{$b} header a.toggle {
    background-position: bottom left;
}

/* ----------------------------------------------------------------------- MAIN --- */
#{$b} main {
    padding-bottom: 4rem;
    @media (max-width: $breakpoint1) {
        padding-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
    }
}

/* -------------------------------------------------------------------- FEATURE --- */
#{$b} section.feature {
    display: flex;
    align-items: center;
    justify-content: center;
}
#{$b} section.feature .media {
    z-index: 1;
}
#{$b} section.feature .media::after {
    @include overlay();
}
#{$b} section.feature .text {
    position: relative;
    z-index: 1;
    color: white;
    font-family: $family_f37;
    font-size: 35px;
    letter-spacing: -0.045em;
    text-align: center;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 28px;
    }
}
#{$b} section.feature .text span {
    display: block;
    @media (max-width: $breakpoint1) {
        display: inline;
    }
}

/* -------------------------------------------------------------------- CONTENT --- */
#{$b} section.feature + section.content {
    padding-top: 6rem;
    @media (max-width: $breakpoint1) {
        padding-top: 4.5rem;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 2.5rem;
    }
}
