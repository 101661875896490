/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// BACKGROUND /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- BACKGROUND --- */
.background {
    position: absolute;
    top: 0;
    left: calc((50vw - 50%) * -1);
    display: none;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    clip: rect(auto auto auto auto);
    background-color: inherit;
    pointer-events: none;
    @media (max-width: $breakpoint2) {
        display: block;
    }
}
.background::before {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 3;
    display: block;
    width: 100vw;
    height: 3.75rem;
    content: '';
    background-color: inherit;
    transform: translateX(-50%);
    opacity: 0.95;
}

/* ----------------------------------------------------------------------- LOGO --- */
.background .logo {
    position: fixed;
    top: 1.25rem;
    left: 1.25rem;
    z-index: 3;
    margin-top: -0.5rem;
    background-position: top;
    transform: translateZ(0);
}
.background .logo::after {
    color: $grey9 !important;
}

/* --------------------------------------------------------------------- TOGGLE --- */
.background .toggle {
    position: fixed;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 3;
    background-position: top left;
    transform: translateZ(0);
}
