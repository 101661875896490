/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// TYPOGRAPHY /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- ROOT --- */
:root {
    font-size: 16px;
}

/* ------------------------------------------------------------------- DOCUMENT --- */
html,
body {
    line-height: 1;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* -------------------------------------------------------------------- HEADING --- */
h1,
h2,
h3,
h4 {
    font-family: $family_f37;
    font-weight: normal;
    line-height: 1.1;
    letter-spacing: -0.045em;
}
h1,
h2 {
    color: $grey9;
    font-size: 35px;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 28px;
    }
}
h3 {
    color: $grey9;
    font-size: 25px;
    text-transform: lowercase;
    @media (max-width: $breakpoint2) {
        font-size: 20px;
    }
}
h6 {
    color: $grey3;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.625rem;
    }
}

/* ------------------------------------------------------------------ PARAGRAPH --- */
p {
    color: $grey9;
    line-height: 1.1;
}

/* ---------------------------------------------------------------------- STYLE --- */
em {
    font-style: italic;
}
strong {
    font-weight: bold;
}

/* --------------------------------------------------------------------- ANCHOR --- */
a {
    color: inherit;
    text-decoration: none;
    transition: color 160ms ease;
    cursor: pointer;
}
