/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// INDEX /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_index';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    padding-top: 0;
    background-color: $grey1;
}

/* --------------------------------------------------------------------- HEADER --- */
#{$b} header a.logo {
    color: white;
}
#{$b} header nav {
    color: white;
}
#{$b} header nav:hover {
    color: rgba(white, 0.5);
}
#{$b} header nav a:hover {
    color: white;
}
#{$b} header a.toggle {
    background-position: bottom left;
}

/* ----------------------------------------------------------------------- MAIN --- */
#{$b} main {
    padding-bottom: 4rem;
    @media (max-width: $breakpoint1) {
        padding-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
    }
}

/* ----------------------------------------------------------------------- DECK --- */
#{$b} .deck .card {
    display: flex;
    align-items: center;
    justify-content: center;
}
#{$b} .deck .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#{$b} .deck .media::after {
    @include overlay();
}
#{$b} .deck nav {
    position: relative;
    z-index: 3;
    display: flex;
    margin: 0 -0.125em;
    color: white;
    font-family: $family_f37;
    font-size: 80px;
    letter-spacing: -0.045em;
    text-align: center;
    text-transform: lowercase;
    pointer-events: all;
    @media (max-width: $breakpoint1) {
        font-size: 50px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 35px;
    }
}
#{$b} .deck nav a {
    margin: 0 0.125em;
}
#{$b} .deck nav a::after {
    content: '.';
}
#{$b} .deck nav a.active {
    color: $fire3;
}

/* ---------------------------------------------------------------------- INTRO --- */
#{$b} section.intro {
    padding: 6rem;
    @media (max-width: $breakpoint1) {
        padding: 4.5rem 2.5rem;
    }
    @media (max-width: $breakpoint2) {
        padding: 2.5rem 0;
    }
}

/* ----------------------------------------------------------------------- NEWS --- */
#{$b} section.news {
    padding: 1.5rem 0 0;
    background-color: $grey1;
    @media (max-width: $breakpoint2) {
        padding: 1.25rem 0 0;
    }
}
#{$b} section.news article:not(:nth-of-type(1)) {
    @media (min-width: ($breakpoint1 + 1px)) {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}
#{$b} section.news article:nth-of-type(2) .media {
    @media (min-width: ($breakpoint1 + 1px)) {
        width: calc(35% - 2rem);
        max-width: 28rem;
    }
}
#{$b} section.news article:nth-of-type(3) .media {
    @media (min-width: ($breakpoint1 + 1px)) {
        margin-left: 16%;
        flex: 1;
    }
}
#{$b} section.news article:nth-of-type(even) {
    @media (max-width: $breakpoint1) and (min-width: ($breakpoint2 + 1px)) {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}
