/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// DOCUMENT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------- DOCUMENT --- */
html,
body {
    overflow-x: hidden;
}

html.active_nav,
html.active_nav body {
    overflow: hidden;
}
html.active_layer,
html.active_layer body {
    overflow: hidden;
}

/* ----------------------------------------------------------------------- HTML --- */
html {
    height: 100%;
    background-color: $grey9;
}

/* ----------------------------------------------------------------------- BODY --- */
body {
    min-height: 100%;
    background-color: white;
    @media (max-width: $breakpoint2) {
        padding-top: 3.75rem;
    }
}
body::before {
    // position: fixed;
    // right: 0;
    // bottom: 0;
    // z-index: 100;
    // padding: 1rem;
    // color: white;
    // font-family: $family_f37;
    // font-size: 1rem;
    // letter-spacing: -0.045em;
    // background-color: $grey9;
    // @media (max-width: $breakpoint0) {
    //     content: 'breakpoint0 - iPad Pro';
    // }
    // @media (max-width: $breakpoint1) {
    //     content: 'breakpoint1 - iPad';
    // }
    // @media (max-width: $breakpoint2) {
    //     content: 'breakpoint2 - Tablet Small';
    // }
    // @media (max-width: $breakpoint3) {
    //     content: 'breakpoint3 - iPhone Plus';
    // }
    // @media (max-width: $breakpoint4) {
    //     content: 'breakpoint4 - iPhone';
    // }
    // @media (max-width: $breakpoint5) {
    //     content: 'breakpoint5 - iPhone SE';
    // }
}
