/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////// 404 /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_404';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    background-color: $fire3;
}

/* --------------------------------------------------------------------- HEADER --- */
#{$b} header nav,
#{$b} header nav a.active {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: $grey9;
    }
}

/* ---------------------------------------------------------------------- ERROR --- */
#{$b} section.error {
    min-height: calc(100vh - (4.125rem + 13.5rem));
    min-height: calc((var(--vh, 1vh) * 100) - (4.125rem + 13.5rem));
    @media (max-width: $breakpoint1) {
        min-height: calc(100vh - (3.625rem + 16.7rem));
        min-height: calc((var(--vh, 1vh) * 100) - (3.625rem + 16.75rem));
    }
    @media (max-width: $breakpoint2) {
        min-height: calc(100vh - (3.75rem + 19.3125rem));
        min-height: calc((var(--vh, 1vh) * 100) - (3.75rem + 19.3125rem));
    }
}
