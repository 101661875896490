/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// HEADER /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- HEADER --- */
header {
    position: relative;
    z-index: 2;
    display: flex;
    max-width: 89rem;
    padding: 1.25rem 1.5rem;
    margin: 0 auto;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: $breakpoint2) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 3.75rem;
        padding: 0;
    }
}
html.active_nav header {
    @media (max-width: $breakpoint2) {
        z-index: 4;
        height: 100%;
        padding-top: 1.875rem;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        background-color: $fire3;
    }
}
header::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    margin-bottom: 1.5rem;
    background-color: $grey9;
    border-radius: 50%;
    transform: translateX(-50%);
}
html.active_nav header::after {
    @media (max-width: $breakpoint2) {
        content: '';
    }
}

/* ----------------------------------------------------------------------- LOGO --- */
header a.logo {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    @media (max-width: $breakpoint2) {
        position: absolute;
        top: 1.25rem;
        left: 1.25rem;
        margin-top: -0.5rem;
    }
}
html.active_nav header a.logo {
    @media (max-width: $breakpoint2) {
        color: $grey9 !important;
    }
}

/* --------------------------------------------------------------------- TOGGLE --- */
header a.toggle {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 2;
    display: none;
    background-position: top left;
    @media (max-width: $breakpoint2) {
        display: block;
    }
}

/* ------------------------------------------------------------------------ NAV --- */
header nav {
    display: flex;
    color: $grey3;
    font-family: $family_f37;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (min-width: ($breakpoint2 + 1px)) {
        margin: 0 -0.5rem;
        font-size: 35px;
    }
    @media (max-width: $breakpoint1) {
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        display: none;
        width: 100%;
        margin-bottom: 20vw;
        align-items: center;
        flex-flow: column;
        color: $grey9 !important;
        font-size: 35px;
    }
}
html.active_nav header nav {
    @media (max-width: $breakpoint2) {
        display: flex;
    }
}
header nav a {
    padding: 0 0.5rem;
    line-height: 0.75;
    @media (max-width: $breakpoint1) {
        padding: 0 0.375rem;
    }
    @media (max-width: $breakpoint2) {
        padding: 0;
        margin: 0.5rem 0;
    }
}
header nav a:hover,
header nav a.active {
    color: $grey9;
}
header nav a:hover {
    @media (pointer: coarse) {
        color: inherit !important;
    }
}

/* --------------------------------------------------------------------- SOCIAL --- */
header .social {
    display: none;
}
html.active_nav header .social {
    @media (max-width: $breakpoint2) {
        display: flex;
    }
}
header .social a {
    background-position-y: top;
}
