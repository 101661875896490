/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// LEGAL /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_legal';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    background-color: $grey1;
}

/* ----------------------------------------------------------------------- LOGO --- */
#{$b} a.logo::after {
    @media (max-width: $breakpoint2) {
        content: 'Legal';
    }
}

/* ----------------------------------------------------------------------- MAIN --- */
#{$b} main {
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media (max-width: $breakpoint1) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}
