/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// NEXT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- NEXT --- */
section.next {
    padding: 1.5rem 0;
    
    @media (max-width: $breakpoint2) {
        padding: 1.25rem 0;
    }
}
section.next::before {
    position: relative;
    z-index: 1;
    display: block;
}
