/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// FONT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------- F37 : REGULAR --- */
@font-face {
    font-family: 'f37';
    font-style: normal;
    font-weight: normal;
    src: url('../font/f37_regular.woff2') format('woff2'),
        url('../font/f37_regular.woff') format('woff');
}

/* ---------------------------------------------------------- POPPINS : REGULAR --- */
@font-face {
    font-family: 'poppins';
    font-style: normal;
    font-weight: normal;
    src: url('../font/poppins_regular.woff2') format('woff2'),
        url('../font/poppins_regular.woff') format('woff');
}

/* ----------------------------------------------------------- POPPINS : MEDIUM --- */
@font-face {
    font-family: 'poppins';
    font-style: normal;
    font-weight: bold;
    src: url('../font/poppins_medium.woff2') format('woff2'),
        url('../font/poppins_medium.woff') format('woff');
}
