/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// CONTACT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- CONTACT --- */
section.contact {
    background-color: white;
}

/* -------------------------------------------------------------------- ADDRESS --- */
section.contact address {
    position: relative;
    display: flex;
    padding-bottom: 4rem;
    align-items: center;
    flex-flow: column;
    font-family: $family_f37;
    font-size: 35px;
    line-height: 1.1;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        padding-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
        font-size: 28px;
    }
}
section.contact address::before {
    display: block;
    margin-bottom: 0.5rem;
    color: $grey3;
    content: 'Get in Touch';
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.375rem;
    }
}
section.contact address::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    background-color: $grey9;
    border-radius: 50%;
    transform: translate(-50%, 50%);
    @media (max-width: $breakpoint2) {
        width: 0.375rem;
        height: 0.375rem;
    }
}
section.contact address > * {
    display: flex;
    align-items: center;
    flex-flow: column;
    text-align: center;
}
section.contact .contact {
    margin-bottom: 3rem;
    line-height: 1.2;
    @media (max-width: $breakpoint1) {
        margin-bottom: 2.5rem;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 2rem;
    }
}
section.contact .location span {
    @media (max-width: $breakpoint3) {
        display: block;
    }
}

/* ----------------------------------------------------------------------- TEAM --- */
section.contact .team {
    position: relative;
    display: flex;
    padding-bottom: 4rem;
    margin-top: 4rem;
    align-items: center;
    flex-flow: column;
    font-family: $family_f37;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        padding-bottom: 3rem;
        margin-top: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
        margin-top: 2.5rem;
    }
}
section.contact .team::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    background-color: $grey9;
    border-radius: 50%;
    transform: translate(-50%, 50%);
    @media (max-width: $breakpoint2) {
        width: 0.375rem;
        height: 0.375rem;
    }
}
section.contact .team a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    @media (max-width: $breakpoint2) {
        display: block;
    }
}
section.contact .team a:not(:last-child) {
    margin-bottom: 3rem;
    @media (max-width: $breakpoint1) {
        margin-bottom: 2.5rem;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 2rem;
    }
}
section.contact .team .name,
section.contact .team .role {
    color: $grey3;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.625rem;
    }
}
section.contact .team .name::after {
    margin-right: 0.25em;
    content: ',';
    @media (max-width: $breakpoint2) {
        content: none;
    }
}
section.contact .team .role {
    margin-bottom: 0.5rem;
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.375rem;
    }
}
section.contact .team .email {
    flex-basis: 100%;
    font-size: 35px;
    @media (max-width: $breakpoint2) {
        font-size: 28px;
    }
}

/* --------------------------------------------------------------------- CAREER --- */
section.contact .career {
    position: relative;
    display: flex;
    margin-top: 4rem;
    align-items: center;
    flex-flow: column;
    font-family: $family_f37;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        margin-top: 3rem;
    }
    @media (max-width: $breakpoint2) {
        margin-top: 2.5rem;
    }
}
section.contact .career::before {
    display: block;
    margin-bottom: 0.5rem;
    color: $grey3;
    content: 'Careers';
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.375rem;
    }
}
section.contact .career p {
    max-width: 26rem;
    font-family: $family_f37;
    font-size: 24px;
    text-align: center;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 18px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 16px;
    }
}
section.contact .career p a {
    text-decoration: underline;
}
