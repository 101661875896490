/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////// BLOCKQUOTE /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ------------------------------------------------------------------ PARAGRAPH --- */
blockquote p {
    font-family: $family_f37;
    letter-spacing: -0.045em;
    text-indent: -0.35em;
    text-transform: lowercase;
}
blockquote p::before {
    content: '\201C';
}
blockquote p::after {
    content: '\201D';
}

/* ----------------------------------------------------------------------- CITE --- */
blockquote cite {
    display: block;
    color: $grey6;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.625rem;
    }
}
