/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// DECK /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- DECK --- */
.deck {
    position: relative;
}

/* ----------------------------------------------------------------------- CARD --- */
.deck .card {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: $grey3;
    transition: opacity 0ms ease 320ms;
    opacity: 0;
}
.deck .card.display {
    z-index: 1;
    transition: opacity 320ms ease;
    opacity: 1;
}

/* -------------------------------------------------------------------- CONTROL --- */
.deck .control {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 3;
    display: flex;
    margin: 1.5rem 0;
    justify-content: center;
    transform: translateX(-50%);
    @media (max-width: $breakpoint2) {
        margin: 1.25rem 0;
    }
}
.deck .control a {
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $grey6;
    border-radius: 50%;
}
.deck .control a:not(:last-child) {
    margin-right: 1rem;
    @media (max-width: $breakpoint2) {
        margin-right: 0.75rem;
    }
}
.deck .control a::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    transform: translate(-50%, -50%);
}
.deck .control a.active {
    background-color: white;
}
