/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// QUOTE /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- QUOTE --- */
section.quote {
    background-color: white;
}

/* ----------------------------------------------------------------- BLOCKQUOTE --- */
section.quote blockquote {
    position: relative;
}
section.quote blockquote p {
    margin-bottom: 1.5rem;
    font-size: 35px;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        margin-bottom: 1rem;
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.75rem;
        font-size: 28px;
    }
}
