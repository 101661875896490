/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// SOCIAL /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- SOCIAL --- */
.social {
    display: flex;
    margin: 0 -1rem;
}

/* --------------------------------------------------------------------- ANCHOR --- */
.social a {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem;
    background-image: url('../gfx/social.png');
    background-repeat: no-repeat;
    background-size: 500% auto;
}
.social a.twitter {
    background-position-x: 0;
}
.social a.facebook {
    background-position-x: 50%;
}
.social a.instagram {
    background-position-x: 100%;
}
