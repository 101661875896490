/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// LAYER /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- LAYER --- */
.layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: none;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 1.5rem;
    align-items: center;
    justify-content: center;
    background-color: rgba($grey9, 0.9);
    @media (max-width: $breakpoint2) {
        padding: 0 1.25rem;
    }
}
.layer.display {
    display: flex;
}

/* ---------------------------------------------------------------------- CLOSE --- */
.layer a.close {
    position: fixed;
    top: 0;
    right: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin: 1.625rem 1.5rem;
    background-image: url('../gfx/close.png');
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: $breakpoint2) {
        margin: 1.25rem;
    }
}

/* ---------------------------------------------------------------------- MEDIA --- */
.layer .media {
    width: 100vw;
    height: calc((9 / 16) * 100vw);
    max-width: 70rem;
    max-height: calc(100% - 3.25rem);
    @media (min-width: ($breakpoint0 + 1)) {
        max-width: 100rem;
    }
    @media (min-aspect-ratio: 16 / 9) {
        width: calc((16 / 9) * 100vh);
        width: calc((16 / 9) * (var(--vh, 1vh) * 100));
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
}
.layer .media::before {
    content: none !important;
}
