/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// FOOTER /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- FOOTER --- */
footer {
    position: relative;
    display: flex;
    max-width: 89rem;
    padding: 3rem;
    margin: 2rem auto 0;
    align-items: center;
    flex-flow: column;
    background-color: $grey9;
    @include background();
    @media (max-width: $breakpoint2) {
        padding: 2.5rem 1rem;
        margin-top: 1.5rem;
    }
}
footer::before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    margin-bottom: 2rem;
    content: '';
    background-color: $grey9;
    border-radius: 50%;
    transform: translateX(-50%);
    @media (max-width: $breakpoint2) {
        margin-bottom: 1.5rem;
    }
}

/* ------------------------------------------------------------------------ NAV --- */
footer nav {
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
    color: white;
    font-family: $family_f37;
    font-size: 35px;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 28px;
    }
}
footer nav a {
    line-height: 0.75;
}
footer nav a::after {
    content: '.';
}
footer nav a:not(:last-child)::after {
    margin-right: 0.25em;
}

/* -------------------------------------------------------------------- CONTENT --- */
footer .content {
    color: white;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15rem;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: $breakpoint1) {
        margin-bottom: 1.5rem;
    }
    @media (max-width: $breakpoint4) {
        margin-bottom: 1rem;
    }
}

/* -------------------------------------------------------------------- ADDRESS --- */
footer address {
    margin-bottom: 0.5rem;
    @media (max-width: $breakpoint2) {
        margin-bottom: 1rem;
    }
}
footer address > div {
    display: flex;
    margin: 0 -0.25em;
    justify-content: center;
    @media (max-width: $breakpoint2) {
        align-items: center;
        flex-flow: column;
        font-size: 0.625rem;
        line-height: 1.6;
    }
}
footer address > div * {
    margin: 0 0.25em;
}
footer address .location {
    margin-bottom: 0.5rem;
    @media (max-width: $breakpoint2) {
        margin-bottom: 1rem;
    }
}

/* -------------------------------------------------------------------- COMPANY --- */
footer .company {
    @media (max-width: $breakpoint2) {
        width: 75%;
        margin: 0 auto;
        align-items: center;
        flex-flow: column;
        font-size: 0.625rem;
        line-height: 1.6;
    }
}

/* ---------------------------------------------------------------------- LEGAL --- */
footer a.legal {
    @media (min-width: ($breakpoint1 + 1px)) {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 3rem;
        transform: translateY(1px);
    }
    @media (max-width: $breakpoint1) {
        margin-bottom: 2rem;
    }
}
footer a.legal::before {
    color: white;
    content: 'Privacy Policy';
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15rem;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.625rem;
    }
}
footer a.legal::after {
    display: inline-block;
    width: 1rem;
    height: 0.5rem;
    margin-left: 0.125rem;
    content: '';
    background-image: url('../gfx/arrow.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    transition: margin-left 160ms ease;
    @media (max-width: $breakpoint2) {
        width: 0.875rem;
        height: 0.4375rem;
    }
}
footer a.legal:hover::after {
    @media (pointer: fine) {
        margin-left: 0.5rem;
    }
}

/* --------------------------------------------------------------------- SOCIAL --- */
footer .social {
    margin-top: -1.5rem;
    align-self: flex-end;
    @media (max-width: $breakpoint1) {
        margin-top: 0;
        align-self: unset;
    }
}
footer .social a {
    background-position-y: bottom;
}
