/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// WORK /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_work.view_detail';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    padding-top: 0;
}

/* --------------------------------------------------------------------- HEADER --- */
#{$b} header a.logo {
    color: white;
}
#{$b} header nav,
#{$b} header nav a.active {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header nav:hover {
    color: rgba(white, 0.5);
}
#{$b} header nav a:hover {
    color: white;
}
#{$b} header a.toggle {
    background-position: bottom left;
}

/* -------------------------------------------------------------------- FEATURE --- */
#{$b} section.feature {
    display: flex;
    align-items: center;
    justify-content: center;
}
#{$b} section.feature .media {
    z-index: 1;
}
#{$b} section.feature .media::after {
    @include overlay();
}
#{$b} section.feature .text {
    position: relative;
    z-index: 2;
    max-width: 75rem;
    color: white;
    font-family: $family_f37;
    font-size: 196px;
    letter-spacing: -0.045em;
    text-align: center;
    text-transform: lowercase;
    @media (max-width: $breakpoint0) {
        max-width: 56rem;
        font-size: 140px;
    }
    @media (max-width: $breakpoint1) {
        font-size: 100px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 60px;
    }
    @media (max-width: $breakpoint3) {
        font-size: 48px;
    }
}
#{$b} section.feature .text.large {
    font-size: 240px;
    @media (max-width: $breakpoint0) {
        font-size: 196px;
    }
    @media (max-width: $breakpoint1) {
        font-size: 140px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 100px;
    }
    @media (max-width: $breakpoint3) {
        font-size: 60px;
    }
}
#{$b} section.feature a.watch {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    display: block;
    width: 10rem;
    padding: 0.5rem 0;
    margin: 1rem 0;
    color: white;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    transform: translateX(-50%);
    pointer-events: all;
    @media (max-width: $breakpoint2) {
        margin: 0.75rem 0;
        font-size: 0.625rem;
    }
}
#{$b} section.feature a.watch::before {
    content: 'Watch Trailer';
}

/* ----------------------------------------------------------------------- NEXT --- */
#{$b} section.next {
    display: flex;
    height: 24vw;
    max-height: 14rem;
    justify-content: space-between;
    color: $grey9;
    font-family: $family_f37;
    font-size: 35px;
    line-height: 1.1;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    background-color: $fire3;
    @media (max-width: $breakpoint1) {
        font-size: 28px;
    }
    @media (max-width: $breakpoint2) {
        display: block;
        height: auto;
        max-height: none;
    }
}
#{$b} section.next::before {
    content: 'Next Up';
    line-height: 0.75;
    @media (max-width: $breakpoint2) {
        margin-bottom: 2rem;
    }
}
#{$b} section.next > a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
#{$b} section.next article {
    width: 50%;
    @media (max-width: $breakpoint2) {
        width: 100%;
    }
}
#{$b} section.next .name {
    display: block;
}
#{$b} section.next .name::after {
    display: inline-block;
    width: 2.1875rem;
    height: 1.125rem;
    margin-left: 0.25em;
    content: '';
    background-image: url('../gfx/arrow.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
    cursor: pointer;
    @media (max-width: $breakpoint2) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1.5rem;
        height: 0.75rem;
        margin-left: 0;
    }
}
#{$b} section.next .director {
    display: block;
    @media (max-width: $breakpoint2) {
        padding-bottom: 16vw;
    }
}
#{$b} section.next .director::before {
    content: 'Directed by' ' ';
}

/* --------------------------------------------------------------------- FOOTER --- */
#{$b} footer {
    margin-top: 0;
}
#{$b} footer::before {
    content: none;
}
