/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// MAIN /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- MAIN --- */
main {
    position: relative;
    display: block;
    width: 100%;
    max-width: 89rem;
    padding: 0 1.5rem;
    margin: 0 auto;
    @media (max-width: $breakpoint2) {
        padding: 0 1.25rem;
    }
}

/* -------------------------------------------------------------------- SECTION --- */
main > section {
    position: relative;
}
main > section:not(.feature) {
    @include background();
}
