/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////// FUNCTION /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------- BACKGROUND --- */
@mixin background() {
    &::after {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 0;
        width: 100vw;
        height: 100%;
        content: '';
        background: inherit;
        transform: translateX(-50%);
    }
    & > *:not(.background) {
        position: relative;
        z-index: 1;
    }
}

/* -------------------------------------------------------------------- OVERLAY --- */
@mixin overlay($opacity: 0.4) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    content: '';
    background-color: rgba(black, $opacity);
}
