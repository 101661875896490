/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// INTRO /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- INTRO --- */
section.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $family_f37;
    font-size: 35px;
    letter-spacing: -0.045em;
    text-align: center;
    text-transform: lowercase;
    background-color: white;
    @media (max-width: $breakpoint1) {
        font-size: 28px;
    }
}

/* -------------------------------------------------------------------- HEADING --- */
section.intro h1 {
    position: relative;
    max-width: 70rem;
}

/* ------------------------------------------------------------------ PARAGRAPH --- */
section.intro p {
    position: relative;
    max-width: 56rem;
}
section.intro p span {
    display: block;
    @media (max-width: $breakpoint0) {
        display: inline;
    }
}
