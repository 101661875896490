/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// ERROR /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- ERROR --- */
section.error {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    text-align: center;
}

/* -------------------------------------------------------------------- HEADING --- */
section.error h1 {
    margin-bottom: 2.5rem;
    font-variant-numeric: oldstyle-nums;
    text-transform: lowercase;
    @media (min-width: ($breakpoint2 + 1px)) {
        font-size: 35px;
    }
    @media (max-width: $breakpoint1) {
        margin-bottom: 2.25rem;
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 2rem;
        font-size: 35px;
    }
}

/* --------------------------------------------------------------------- ANCHOR --- */
section.error a {
    display: flex;
    margin-left: -1.5rem;
    align-items: center;
    color: white;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.625rem;
    }
}
section.error a::before {
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    content: '';
    background-image: url('../gfx/arrow.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    transform: rotate(180deg);
    cursor: pointer;
}
