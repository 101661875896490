/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// NEWS /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- ARTICLE --- */
section.news article {
    display: flex;
    margin: 0 -1rem;
    @media (max-width: $breakpoint2) {
        position: relative;
        margin: 0;
        flex-flow: column;
    }
}
section.news article:not(:last-child) {
    margin-bottom: 4rem;
    @media (max-width: $breakpoint1) {
        margin-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 2.5rem;
    }
}

/* -------------------------------------------------------------------- CONTENT --- */
section.news .content {
    position: relative;
    display: flex;
    width: calc(35% - 2rem);
    max-width: 28rem;
    margin: 0 1rem;
    flex: 0 0 auto;
    flex-flow: column;
    justify-content: flex-start;
    @media (max-width: $breakpoint2) {
        position: static;
        width: 100%;
        margin: 0;
        margin-bottom: 1.5rem;
    }
}
section.news .content::after {
    display: block;
    width: 2.1875rem;
    height: 1.125rem;
    margin-top: auto;
    content: '';
    background-image: url('../gfx/arrow.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
    transition: margin-left 160ms ease;
    cursor: pointer;
    @media (max-width: $breakpoint2) {
        position: absolute;
        bottom: 0;
        width: 1.5rem;
        height: 0.75rem;
    }
    @media (max-width: $breakpoint5) {
        content: none;
    }
}
section.news article:hover .content::after {
    @media (pointer: fine) {
        margin-left: 1rem;
    }
}
section.news article:nth-of-type(odd) .content::after {
    @media (max-width: $breakpoint2) {
        right: 0;
    }
}
section.news .content time {
    display: block;
    margin-bottom: 0.5rem;
    color: $grey3;
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.625rem;
    }
}

/* ---------------------------------------------------------------------- MEDIA --- */
section.news .media {
    width: calc(65% - 2rem);
    max-width: 56rem;
    margin: 0 1rem;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: $breakpoint2) {
        width: calc(100% - 3rem);
        margin: 0;
    }
    @media (max-width: $breakpoint5) {
        width: 100%;
    }
}
section.news article:nth-of-type(even) .media {
    @media (max-width: $breakpoint2) {
        align-self: flex-end;
    }
}
section.news .media::before {
    padding-top: 55%;
}
section.news .media::after {
    transition: opacity 320ms ease;
    opacity: 0;
    @media (pointer: fine) {
        @include overlay();
    }
}
section.news article:hover .media::after {
    opacity: 1;
}
