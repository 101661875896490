/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// AWARD /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- AWARD --- */
section.award {
    display: flex;
    max-width: 54rem;
    padding-top: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $grey9;
    @media (max-width: $breakpoint1) {
        padding-top: 2.25rem;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 1.5rem;
    }
}
section.award::before {
    position: relative;
    z-index: 1;
    display: block;
    padding-top: 4.75rem;
    margin-bottom: 1.25rem;
    flex-basis: 100%;
    color: white;
    content: 'Accolades Include';
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    background-image: url('../gfx/award.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 4.375rem;
    @media (max-width: $breakpoint2) {
        padding-top: 3rem;
        margin-bottom: 1rem;
        font-size: 0.625rem;
        background-size: 2.625rem;
    }
}
section.award > div {
    margin-right: 0.25em;
    color: white;
    font-family: $family_f37;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: -0.045em;
    text-align: center;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 18px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 16px;
    }
}
section.award > div:not(:last-child)::after {
    content: ',';
}

/* -------------------------------------------------------------------- CONTENT --- */
section.award + section.content {
    padding-top: 6rem;
    @media (max-width: $breakpoint1) {
        padding-top: 4.5rem;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 2.5rem;
    }
}
