/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// WORK /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- WORK --- */
section.work {
    display: flex;
    margin: 0 -1rem;
    flex-wrap: wrap;
    background-color: white;
    @media (max-width: $breakpoint2) {
        margin: 0 -0.75rem;
    }
}

/* -------------------------------------------------------------------- ARTICLE --- */
section.work:not(.film) article {
    width: calc(33.333% - 2rem);
    margin: 2rem 1rem 0;
    @media (max-width: $breakpoint1) {
        width: calc(50% - 2rem);
    }
    @media (max-width: $breakpoint2) {
        width: calc(100% - 2rem);
    }
}
section.work.film article {
    width: calc(33.333% - 2rem);
    margin: 2rem 1rem 0;
    @media (max-width: $breakpoint2) {
        width: calc(50% - 1.5rem);
        margin: 1.5rem 0.75rem 0;
    }
    @media (max-width: $breakpoint4) {
        width: calc(100% - 1.5rem);
    }
}
section.work:not(.film) article:nth-of-type(-n+3) {
    @media (min-width: ($breakpoint1 + 1px)) {
        margin-top: 0;
    }
}
section.work.film article:nth-of-type(-n+3) {
    @media (min-width: ($breakpoint2 + 1px)) {
        margin-top: 0;
    }
}
section.work:not(.film) article:nth-of-type(-n+2) {
    @media (max-width: $breakpoint1) and (min-width: ($breakpoint2 + 1px)) {
        margin-top: 0;
    }
}
section.work.film article:nth-of-type(-n+2) {
    @media (max-width: $breakpoint1) and (min-width: ($breakpoint4 + 1px)) {
        margin-top: 0;
    }
}
section.work:not(.film) article:nth-of-type(1) {
    @media (max-width: $breakpoint2) {
        margin-top: 0;
    }
}
section.work.film article:nth-of-type(1) {
    @media (max-width: $breakpoint4) {
        margin-top: 0;
    }
}

/* ---------------------------------------------------------------------- MEDIA --- */
section.work .media {
    margin-bottom: 1rem;
}
section.work .media::after {
    transition: opacity 320ms ease;
    opacity: 0;
    @media (pointer: fine) {
        @include overlay();
    }
}
section.work article:hover .media::after {
    opacity: 1;
}
section.work.film .media::before {
    padding-top: 150%;
}
section.work.tv .media::before,
section.work.branded .media::before {
    padding-top: 56.25%;
}

/* ----------------------------------------------------------------------- NAME --- */
section.work .name {
    display: block;
    font-family: $family_poppins;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        font-size: 0.75rem;
    }
}
