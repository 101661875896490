/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// FEATURE /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- FEATURE --- */
section.feature {
    left: calc((50vw - 50%) * -1);
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 1.5rem;
    margin-top: -4.125rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: $breakpoint1) {
        margin-top: -3.625rem;
    }
    @media (max-width: $breakpoint2) {
        padding: 0 1.25rem;
        margin-top: 0;
    }
}

/* ---------------------------------------------------------------------- MEDIA --- */
section.feature > .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ---------------------------------------------------------------------- ARROW --- */
section.feature a.arrow {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 1.125rem;
    height: 2.1875rem;
    margin: 1.5rem;
    transition: height 160ms ease;
    @media (max-width: $breakpoint2) {
        width: 1.5rem;
        height: 1.5rem;
        margin: 1.25rem;
    }
}
section.feature a.arrow::before {
    position: absolute;
    left: 50%;
    display: block;
    width: 2.1875rem;
    height: 2.1875rem;
    content: '';
    background-image: url('../gfx/arrow.png');
    background-repeat: no-repeat;
    background-position: 100% 150%;
    background-size: 100% auto;
    transform: translateX(-50%) rotate(90deg);
    pointer-events: none;
    @media (min-width: ($breakpoint2 + 1px)) {
        top: 0;
    }
    @media (max-width: $breakpoint2) {
        bottom: 0;
    }
}
section.feature a.arrow:hover {
    height: 3.1875rem;
}
