/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// DETAIL /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- DETAIL --- */
section.detail {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 6rem;
    align-items: flex-start;
    background-color: white;
    @media (max-width: $breakpoint1) {
        max-width: 54rem;
        padding-bottom: 4.5rem;
        margin: 0 auto;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 1.25rem;
        padding-bottom: 2.5rem;
        flex-wrap: wrap;
    }
}

/* -------------------------------------------------------------------- HEADING --- */
section.detail h6 {
    margin-bottom: 0.5rem;
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.375rem;
    }
}

/* ------------------------------------------------------------------- EXTERNAL --- */
section.detail .external {
    width: calc(((100% - 54rem) / 2) - 2rem);
    margin-right: 2rem;
    flex: 0 0 auto;
    @media (max-width: $breakpoint0) {
        width: 20%;
    }
    @media (max-width: $breakpoint1) {
        display: none;
    }
}
section.detail .watch {
    margin-bottom: 1.75rem;
}
section.detail .watch::before {
    display: block;
    margin-bottom: 0.75rem;
    color: $grey3;
    content: 'Watch On';
    font-family: $family_poppins;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.625rem;
    }
}
section.detail .watch a.provider {
    display: block;
    width: 6rem;
    background-repeat: no-repeat;
    background-size: contain;
}
section.detail .watch a.provider.netflix {
    height: 1.625rem;
    background-image: url('../gfx/netflix.png');
}
section.detail .watch a.provider.bbc {
    height: 1.75rem;
    background-image: url('../gfx/bbc.png');
}
section.detail .watch a.provider.aljazeera {
    height: 7.375rem;
    background-image: url('../gfx/aljazeera.png');
}
section.detail .watch a[class$=provider] {
    width: auto;
    font-family: $family_f37;
    font-size: 35px;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 28px;
    }
}
section.detail .link {
    color: $grey3;
    font-family: $family_poppins;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.375;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}
section.detail .link::after {
    display: table;
    clear: both;
    content: '';
}
section.detail .link a {
    float: left;
    clear: left;
}

/* ----------------------------------------------------------------------- META --- */
section.detail .meta {
    width: 100%;
    max-width: 19rem;
    margin-right: 2rem;
    flex: 0 0 auto;
    @media (max-width: $breakpoint0) {
        width: 30%;
    }
    @media (max-width: $breakpoint2) {
        width: 100%;
        margin-right: 0;
        order: 2;
    }
}
section.detail .meta .item:not(:last-child) {
    margin-bottom: 1.25rem;
    @media (max-width: $breakpoint2) {
        margin-bottom: 1rem;
    }
}
section.detail .meta .value {
    color: $grey9;
    font-family: $family_f37;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: -0.045em;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 18px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 16px;
    }
}

/* ----------------------------------------------------------------------- TEXT --- */
section.detail .text {
    width: 100%;
    max-width: 33rem;
    @media (max-width: $breakpoint2) {
        margin-bottom: 2rem;
        order: 1;
    }
}
section.detail .text p {
    font-family: $family_f37;
    font-size: 24px;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 18px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 16px;
    }
}
