/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// CONTENT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------- CONTENT --- */
section.content {
    max-width: 54rem;
    margin-right: auto;
    margin-left: auto;
}
section.content:not(:last-of-type) {
    padding-bottom: 6rem;
    @media (max-width: $breakpoint1) {
        padding-bottom: 4.5rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
    }
}

/* ----------------------------------------------------------------------- MISC --- */
section.content h6 {
    position: relative;
    width: 16%;
    margin-right: 3rem;
    line-height: 1.5;
    @media (max-width: $breakpoint1) {
        width: unset;
        margin: 0 0 0.5rem;
    }
}

/* -------------------------------------------------------------------- ARTICLE --- */
section.content article {
    position: relative;
    flex: 1;
}
