/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// IMAGE /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- IMAGE --- */
section.image {
    display: flex;
    background-color: white;
    @media (max-width: $breakpoint2) {
        display: block;
    }
}
section.image.next_image {
    @media (min-width: ($breakpoint2 + 1px)) {
        padding-bottom: 2rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 1.5rem;
    }
}

/* ---------------------------------------------------------------------- MEDIA --- */
section.image .media {
    position: relative;
    flex: 1;
}
section.image .media:not(:last-child) {
    @media (min-width: ($breakpoint2 + 1px)) {
        margin-right: 2rem;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 1.5rem;
    }
}
section.image .media::before {
    padding-top: 56.25%;
}
