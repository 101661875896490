/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// LEGAL /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- LEGAL --- */
section.legal {
    max-width: 54rem;
    margin: 0 auto;
    background-color: $grey1;
}

/* -------------------------------------------------------------------- HEADING --- */
section.legal h1 {
    margin: 0.5rem 0 1.5em;
    text-align: center;
}
section.legal h2 {
    margin-bottom: 0.75em;
}
section.legal p + h2 {
    margin-top: 1em;
}
section.legal p + h6,
section.legal h2 + h6 {
    margin-bottom: 0.5em;
}
section.legal p + h6 {
    margin-top: 2em;
}
section.legal h6:first-of-type {
    text-align: center;
}

/* ------------------------------------------------------------------ PARAGRAPH --- */
section.legal p {
    font-family: $family_poppins;
    font-size: 0.875rem;
    line-height: 1.5;
    @media (max-width: $breakpoint2) {
        font-size: 0.8125rem;
    }
}
section.legal p:not(:last-child) {
    margin-bottom: 1em;
}
section.legal h1 + p {
    margin-bottom: 3.5em !important;
}

/* ----------------------------------------------------------------------- LIST --- */
section.legal ul,
section.legal ol {
    color: $grey9;
    font-family: $family_poppins;
    font-size: 0.875rem;
    line-height: 1.5;
    @media (max-width: $breakpoint2) {
        font-size: 0.8125rem;
    }
}
section.legal li:not(:last-child) {
    margin-bottom: 0.125em;
}

/* ------------------------------------------------------------------ LIST : UL --- */
section.legal ul {
    padding-left: 2rem;
    list-style-type: disc;
}
section.legal ul:not(:last-child) {
    margin-bottom: 1em;
}

/* ------------------------------------------------------------------ LIST : OL --- */
section.legal ol {
    list-style-position: inside;
    list-style-type: decimal;
}
section.legal ol:not(:last-child) {
    margin-bottom: 1em;
}
section.legal ol.large {
    font-family: $family_f37;
    font-size: 35px;
    line-height: 1.1;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 28px;
    }
}
section.legal ol.large:not(:last-child) {
    margin-bottom: 1.5em;
}
section.legal ol.large li:not(:last-child) {
    margin-bottom: 0.5em;
}

/* --------------------------------------------------------------------- ANCHOR --- */
section.legal a {
    text-decoration: underline;
}
