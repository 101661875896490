/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// NEWS /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_news.view_detail';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    @media (min-width: ($breakpoint2 + 1px)) {
        padding-top: 0;
    }
}

/* --------------------------------------------------------------------- HEADER --- */
#{$b} header a.logo {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header nav {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header nav:hover {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: rgba(white, 0.5);
    }
}
#{$b} header nav a:hover {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header nav a.active {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header a.toggle {
    @media (min-width: ($breakpoint2 + 1px)) {
        background-position: bottom left;
    }
}

/* ----------------------------------------------------------------------- MAIN --- */
#{$b} main {
    @media (max-width: $breakpoint2) {
        padding-top: 2.5rem;
    }
}

/* -------------------------------------------------------------------- FEATURE --- */
#{$b} section.feature {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    min-height: 32rem;
    @media (max-width: $breakpoint2) {
        height: unset;
        min-height: unset;
    }
}
#{$b} section.feature::before {
    @media (max-width: $breakpoint2) {
        display: block;
        padding-top: 56.25%;
        content: '';
    }
}
#{$b} section.feature::after {
    content: none;
}
#{$b} section.feature .background .logo {
    @media (max-width: $breakpoint2) {
        background-position: bottom;
    }
}
#{$b} section.feature .background .toggle {
    @media (max-width: $breakpoint2) {
        background-position-y: bottom;
    }
}
#{$b} section.feature .media {
    z-index: 1;
}
#{$b} section.feature .media::after {
    @include overlay();
}

/* -------------------------------------------------------------------- CONTENT --- */
#{$b} section.feature + section.content {
    padding-top: 6rem;
    @media (max-width: $breakpoint1) {
        padding-top: 4.5rem;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 2.5rem;
    }
}

/* ----------------------------------------------------------------------- NEXT --- */
#{$b} section.next {
    background-color: $grey1;
}
#{$b} section.next::before {
    margin-bottom: 0.75rem;
    color: $grey3;
    content: 'Next Story';
    font-family: $family_poppins;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.5rem;
        font-size: 0.625rem;
    }
}
#{$b} section.next article time {
    display: none;
}

/* --------------------------------------------------------------------- FOOTER --- */
#{$b} footer {
    margin-top: 0;
}
#{$b} footer::before {
    content: none;
}
