/* //////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////// MEDIA /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- MEDIA --- */
.media.image {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: $grey3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.media.image::before {
    display: block;
    content: '';
}
.media.image.active > div {
    opacity: 1 !important;
}
.media.image > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
}
.media.image > div:first-child {
    margin: -0.25rem;
    filter: blur(0.25rem);
}
.media.image > div:last-child {
    z-index: 1;
    transition: opacity 320ms ease 320ms;
    opacity: 0;
}

/* ---------------------------------------------------------------------- VIDEO --- */
.media.video {
    position: relative;
}
.media.video::before {
    display: block;
    padding-top: 56.25%;
    content: '';
}
.media.video .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 160ms ease 80ms;
    opacity: 1;
}
.media.video.active_play .media {
    opacity: 0;
    pointer-events: none;
}
.media.video .media::before {
    content: none;
}
.media.video a.play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 8%;
    max-width: 4rem;
    min-width: 2rem;
    background-image: url('../gfx/play.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translate(-50%, -50%);
    transition: opacity 160ms ease;
    opacity: 1;
}
.media.video.active_load a.play {
    opacity: 0.75;
    pointer-events: none;
}
.media.video.active_play a.play {
    opacity: 0;
}
.media.video a.play::before {
    display: block;
    padding-top: 121%;
    content: '';
}
.media.video .player {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.media.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
