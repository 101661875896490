/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////// VAR /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ---------------------------------------------------------------------- COLOR --- */
/* grey */
$grey1: #f3f0f0;
$grey3: #9b998f;
$grey6: #5a5a5a;
$grey9: #1e1e1e;

/* fire */
$fire3: #f7654c;

/* --------------------------------------------------------------------- FAMILY --- */
$family_f37: ('f37', sans-serif);
$family_poppins: ('poppins', sans-serif);

/* ----------------------------------------------------------------- BREAKPOINT --- */
$breakpoint0: 1280px;   // 1280px : iPad Pro
$breakpoint1: 960px;    //  960px : iPad
$breakpoint2: 640px;    //  640px : Tablet Small
$breakpoint3: 480px;    //  480px : iPhone Plus
$breakpoint4: 400px;    //  400px : iPhone
$breakpoint5: 360px;    //  360px : iPhone SE

/* ---------------------------------------------------------------------- NOTES --- */
/*
col = 80
gut = 32
    = 1376
*/
