/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// TEXT /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- TEXT --- */
section.text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    @media (max-width: $breakpoint1) {
        display: block;
    }
}

/* ------------------------------------------------------------------ PARAGRAPH --- */
section.text article {
    position: relative;
}
section.text article p {
    font-family: $family_poppins;
    font-size: 0.875rem;
    line-height: 1.5;
    @media (max-width: $breakpoint2) {
        font-size: 0.8125rem;
    }
}
section.text article p:not(:last-child) {
    margin-bottom: 1em;
}

/* --------------------------------------------------------------------- ANCHOR --- */
section.text article a {
    text-decoration: underline;
}
section.text article a:hover {
    color: $grey6;
}
