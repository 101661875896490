/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// REVIEW /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- REVIEW --- */
section.review {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    @media (max-width: $breakpoint1) {
        display: block;
    }
}

/* ----------------------------------------------------------------- BLOCKQUOTE --- */
section.review blockquote {
    position: relative;
    flex: 1;
}
section.review blockquote:not(:last-child) {
    margin-bottom: 1.5rem;
    @media (max-width: $breakpoint1) {
        margin-bottom: 1.25rem;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 1rem;
    }
}
section.review blockquote p {
    margin-bottom: 0.75rem;
    font-size: 24px;
    @media (max-width: $breakpoint1) {
        font-size: 18px;
    }
    @media (max-width: $breakpoint2) {
        margin-bottom: 0.5rem;
        font-size: 16px;
    }
}
