/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// TEAM /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* -------------------------------------------------------------------------- B --- */
$b: 'body.page_team';

/* ----------------------------------------------------------------------- BODY --- */
#{$b} {
    padding-top: 0;
}

/* ----------------------------------------------------------------------- LOGO --- */
#{$b} a.logo::after {
    color: white;
    @media (max-width: $breakpoint2) {
        content: 'team';
    }
}

/* --------------------------------------------------------------------- HEADER --- */
#{$b} header a.logo {
    color: white;
}
#{$b} header nav,
#{$b} header nav a.active {
    @media (min-width: ($breakpoint2 + 1px)) {
        color: white;
    }
}
#{$b} header nav:hover {
    color: rgba(white, 0.5);
}
#{$b} header nav a:hover {
    color: white;
}
#{$b} header a.toggle {
    background-position: bottom left;
}

/* ----------------------------------------------------------------------- MAIN --- */
#{$b} main {
    padding-bottom: 4rem;
    @media (max-width: $breakpoint1) {
        padding-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
    }
}

/* -------------------------------------------------------------------- FEATURE --- */
#{$b} section.feature {
    display: flex;
    align-items: center;
    justify-content: center;
}
#{$b} section.feature .media {
    z-index: 1;
}
#{$b} section.feature .media::after {
    @include overlay();
}
#{$b} section.feature .text {
    position: relative;
    z-index: 1;
    color: white;
    font-family: $family_f37;
    font-size: 35px;
    letter-spacing: -0.045em;
    text-align: center;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        font-size: 28px;
    }
}
#{$b} section.feature .text span {
    display: block;
    @media (max-width: $breakpoint1) {
        display: inline;
    }
}

/* -------------------------------------------------------------------- CONTENT --- */
#{$b} section.feature + section.content {
    padding-top: 6rem;
    @media (max-width: $breakpoint1) {
        padding-top: 4.5rem;
    }
    @media (max-width: $breakpoint2) {
        padding-top: 2.5rem;
    }
}

/* ---------------------------------------------------------------------- team --- */
#{$b} section.team {
    max-width: 54rem;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
}
#{$b} section.team article {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: $breakpoint1) {
        display: block;
    }
}
#{$b} section.team article:not(:last-child) {
    padding-bottom: 4rem;
    @media (max-width: $breakpoint1) {
        padding-bottom: 3rem;
    }
    @media (max-width: $breakpoint2) {
        padding-bottom: 2.5rem;
    }
}
#{$b} section.team .person {
    width: 16%;
    margin-right: 3rem;
    flex: 0 0 auto;
    @media (max-width: $breakpoint1) {
        width: unset;
        margin: 0 0 0.5rem;
    }
}
#{$b} section.team h6 {
    margin-top: 0.75rem;
    line-height: 1.5;
}
#{$b} section.team .text {
    width: calc(84% - 3rem);
    @media (max-width: $breakpoint1) {
        width: unset;
    }
}
#{$b} section.team p {
    font-family: $family_poppins;
    font-size: 0.875rem;
    line-height: 1.5;
    @media (max-width: $breakpoint2) {
        font-size: 0.8125rem;
    }
}
#{$b} section.team p:not(:last-child) {
    margin-bottom: 1em;
}
#{$b} section.team p a {
    text-decoration: underline;
}
#{$b} section.team p a:hover {
    color: $grey6;
}
#{$b} section.team a.email {
    display: block;
    margin-top: 1em;
    margin-left: calc(16% + 3rem);
    flex-basis: 100%;
    font-family: $family_poppins;
    font-size: 1rem;
    letter-spacing: -0.045em;
    text-transform: lowercase;
    @media (max-width: $breakpoint1) {
        margin-left: unset;
    }
}
