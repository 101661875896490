/* //////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////// LOGO /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* ----------------------------------------------------------------------- LOGO --- */
.logo {
    display: flex;
    font-family: $family_f37;
    line-height: 0.75;
    letter-spacing: -0.045em;
    transition: none;
    @media (min-width: ($breakpoint2 + 1px)) {
        font-size: 35px;
    }
    @media (max-width: $breakpoint1) {
        font-size: 24px;
    }
    @media (max-width: $breakpoint2) {
        font-size: 35px;
    }
}
a.logo::before {
    content: '\2600';
}
a.logo::after {
    @media (max-width: $breakpoint2) {
        position: absolute;
        top: 0;
        left: 100%;
        display: block;
        padding-left: 0.875rem;
        content: attr(data-text);
        line-height: 0.75;
        text-transform: lowercase;
        white-space: nowrap;
    }
}
html.active_nav a.logo::after {
    @media (max-width: $breakpoint2) {
        content: none !important;
    }
}
