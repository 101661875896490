/* //////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////// TOGGLE /// */
/* //////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------------------------------------- TOGGLE --- */
a.toggle {
    width: 1.25rem;
    height: 1.25rem;
    background-image: url('../gfx/toggle.png');
    background-repeat: no-repeat;
    background-size: 300% auto;
}
html.active_nav a.toggle {
    background-position: top right !important;
}
